import React from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import pendingAnimation from '../assets/dbWVSy0hCw.json'; // Replace with your actual Lottie JSON file path
import './pending.scss'; // Assuming you’ll create custom styles in this file

const Pending = () => {
  const navigate = useNavigate();

  const handleBackToLogin = () => {
    navigate("/Login");
  };

  return (
    <div className="pending-container">
      <div className="pending-content">
        <Lottie animationData={pendingAnimation} className="pending-animation" />
        <div className="text-content">
          <h2>Your account is pending approval</h2>
          <p>Thank you for signing up. Please wait while we review your account. You’ll be notified once it’s approved.</p>
          <button className="back-to-login-button" onClick={handleBackToLogin}>
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pending;
