import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import Login from "../src/login/Login"
import Signup from '../src/signup/Signup';
import Home from '../src/Home/Home'
import History from '../src/history/History';
import Pending from './pending/Pending';

const App = () =>{
    return (
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />  {/* Route for Home */}
          <Route path="/Signup" element={<Signup />} />  {/* Route for Home */}
          <Route path="/Login" element={<Login />} />  {/* Route for Home */}
          <Route path="/Home" element={<Home />} />  {/* Route for Home */}
          <Route path="/History" element={<History />} />  {/* Route for Home */}
          <Route path="/Pending" element={<Pending />} />  {/* Route for Home */}
          
          {/* Add other routes here */}
        </Routes>
      </BrowserRouter>
    )
}
export default App;