import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './history.scss';
import Logo from '../assets/photo_2_2024-08-17_15-14-53-removebg-preview.png';
import ProfileImage from '../assets/images.jpg';
import { useNavigate } from 'react-router-dom';

const History = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null); // Added state for email
  const [fullName, setFullName] = useState(null); // Full name to display on profile
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the user's profile to get the full name and email
    axios.get('http://localhost:5001/api/partners/profile', { withCredentials: true })
      .then(response => {
        setUserInfo(response.data);
        if (response.data) {
          setFullName(response.data.full_name); // Set the full name for display
          setEmail(response.data.email); // Set email for fetching orders
        }
      })
      .catch(error => {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data.');
      });
  }, []);

  useEffect(() => {
    if (email) {
      // Fetch order history using email
      axios.get(`http://localhost:5001/api/orders/history?email=${encodeURIComponent(userInfo.email)}`, { withCredentials: true })
  .then(response => {
    console.log("Fetched orders:", response.data);
    setOrders(response.data);
  })
  .catch(error => {
    console.error("Error fetching orders:", error);
    setError('Error fetching order data.');
  });

    }
  }, [email]); // Depend on email to trigger fetching orders after it's set

  const handleNavigate = () => {
    navigate('/History');
  };

  const handleHome = () => {
    navigate('/Home');
  };

  return (
    <div className="history-container">
      <header className="header">
        <div className="logo-container">
          <img className="logo" src={Logo} alt="Logo" />
        </div>
        <nav className="nav-bar">
          <a onClick={handleHome} className="nav-link">Home</a>
          <a onClick={handleNavigate} className="nav-link">History</a>
        </nav>
        <div className="profile-container">
          {error ? (
            <span className="error-message">{error}</span>
          ) : (
            <div>
              <div className="profile-image">
                <img src={ProfileImage} alt="Profile" />
              </div>
              <span className="profile-name">
                {fullName || 'Loading...'}
              </span>
            </div>
          )}
        </div>
      </header>

      <section className="hero">
        <div className="hero-content">
          <h1>Your Product Awaits</h1>
          <p>Experience excellence with our top-of-the-line roasted chickens. Easy ordering.</p>
        </div>
      </section>

      <section className="order-history">
        <h2>Order History</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="history-list">
          {orders.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Product</th>
                  <th>Quantity</th>
                  <th>Status</th>
                  <th>Order Date</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>{order.order_details}</td>
                    <td>{order.quantity}</td>
                    <td>{order.status}</td>
                    <td>{new Date(order.created_at).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No orders found.</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default History;
